import React from "react";
import { Box, Typography, Card, CardContent, CardMedia, Button } from "@mui/material";
import { styled } from "@mui/system";

// Styled components
const SectionContainer = styled(Box)({
  padding: "40px",
  backgroundColor: "#f5f5f5",
  textAlign: "center",
});

const PackageCard = styled(Card)({
  margin: "20px auto",
  maxWidth: "350px",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const OrderButton = styled(Button)({
  marginTop: "15px",
  backgroundColor: "#593EA1",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#452F82",
  },
});

const ProductContainerSection = () => {
  const packages = [
    {
      title: "4 Magnets Package",
      price: "$14",
      description: "Perfect for small memories. Customize 4 fridge magnets with your favorite pictures!",
      image: "/images/package4.jpg",
    },
    {
      title: "6 Magnets Package",
      price: "$22",
      description: "Create a set of 6 magnets to relive beautiful moments every day.",
      image: "/images/package6.jpg",
    },
    {
      title: "8 Magnets Package",
      price: "$29",
      description: "Get 8 customized magnets to capture all your cherished memories.",
      image: "/images/package8.jpg",
    },
  ];

  return (
    <SectionContainer>
      <Typography variant="h4" style={{ marginBottom: "30px", fontWeight: "bold", color: "#333" }}>
        Select Your Package
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
        gap="20px"
      >
        {packages.map((pkg, index) => (
          <PackageCard key={index}>
            <CardMedia
              component="img"
              height="200"
              image={pkg.image}
              alt={pkg.title}
              style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
            />
            <CardContent>
              <Typography variant="h6" style={{ fontWeight: "bold", color: "#593EA1" }}>
                {pkg.title}
              </Typography>
              <Typography variant="body1" style={{ margin: "10px 0", color: "#666" }}>
                {pkg.description}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: "bold", color: "#333" }}>
                {pkg.price}
              </Typography>
              <OrderButton variant="contained">Order Now</OrderButton>
            </CardContent>
          </PackageCard>
        ))}
      </Box>
    </SectionContainer>
  );
};

export default ProductContainerSection;
