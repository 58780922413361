import React, { useRef, useEffect, useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { db } from './firebaseConfig';
import '../Css/OrderForm_css.css';
import Footer from './Footer';
import ProductContainer from './ProductContainer';
import ReviewsSection from './ReviewsSection';
import OrderNotes from './OrderNotes'; // Import the new OrderNotes component
import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const OrderForm = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [orderDetails, setOrderDetails] = useState({ name: '', email: '', phone: '', address: '' });
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false); // Track uploading state
  const [error, setError] = useState('');
  const orderFormRef = useRef(null);
  const refId = useRef(uuidv4()); // Use useRef to keep refId consistent across renders

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  
  useEffect(() => {
    if (selectedPackage && orderFormRef.current) {
      orderFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPackage]);

  // New useEffect to listen for authentication state changes
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          email: user.email
        }));
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  const packages = [
    { id: 1, name: 'Package 1', price: 10, magnets: 3, description: '3 fridge magnets, 5x5 cm ' },
    { id: 2, name: 'Package 2', price: 16, magnets: 6, description: '6 fridge magnets +1 Free, 5x5 cm ' },
    { id: 3, name: 'Package 3', price: 20, magnets: 9, description: '9 fridge magnets +2 Free, 5x5 cm ' },
  ];

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
    setImageUrls([]);
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const deleteExistingImages = async () => {
    const storage = getStorage();
    const folderRef = ref(storage, `images/${refId.current}`);
    try {
      const listResult = await listAll(folderRef);
      const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error deleting existing images:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    if (files.length !== selectedPackage.magnets) {
      setError(`Please upload exactly ${selectedPackage.magnets} images.`);
      return;
    }

    setUploading(true); // Start uploading
    await deleteExistingImages(); // Delete existing images before uploading new ones
    const storage = getStorage();
    const uploadPromises = Array.from(files).map((file) => {
      const storageRef = ref(storage, `images/${refId.current}/${file.name}`);
      return uploadBytes(storageRef, file).then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        return downloadUrl;
      });
    });

    try {
      const urls = await Promise.all(uploadPromises);
      if (urls.length !== selectedPackage.magnets) {
        setError(`Failed to upload ${selectedPackage.magnets} images. Please try again.`);
        return;
      }
      setImageUrls(urls);
      setError('');
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Failed to upload images. Please try again.");
    } finally {
      setUploading(false); // Finish uploading
    }
  };

  const calculateTotalPrice = () => {
    if (selectedPackage) {
      return selectedPackage.price;
    }
    return 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (imageUrls.length !== selectedPackage.magnets) {
      setError(`Please upload exactly ${selectedPackage.magnets} images.`);
      return;
    }

    const orderData = {
      Address: orderDetails.address,
      Date: Timestamp.now(),
      Email: orderDetails.email,
      Name: orderDetails.name,
      Package: selectedPackage.name,
      Phone: orderDetails.phone,
      Price: calculateTotalPrice(),
      client_feedback: null,
      imageUrls: imageUrls,
      order_delivered: false,
      order_done: false,
      refId: refId.current,
    };

    try {
      const docRef = await addDoc(collection(db, 'orders'), orderData);
      console.log('Document written with ID: ', docRef.id);
      setSelectedPackage(null);
      setOrderDetails({ name: '', email: '', phone: '', address: '' });
      setImageUrls([]);
      alert("Order submitted successfully!");
      window.location.reload();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div  ref={orderFormRef} className="product-page">
      <OrderNotes />
      <div className="product-selection">
        <h1 className="order-title">Select Your Magnet Package</h1>
        <div className="packages">
          {packages.map((pkg) => (
            <div key={pkg.id} className="package-card" onClick={() => handlePackageSelect(pkg)}>
              <div className="package-bg">
                <h2>{pkg.name}</h2>
                <p>{pkg.description}</p>
                <p>${pkg.price}</p>
              </div>
            </div>
          ))}
        </div>
        {selectedPackage && (
          <div  ref={orderFormRef} className="order-form">
            <h2>Order Details</h2>
            <p>Selected Package: {selectedPackage.name}</p>
            <p>Magnets: {selectedPackage.magnets}</p>
            <p>Price per Package: ${selectedPackage.price}</p>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input type="text" name="name" value={orderDetails.name} onChange={handleInputChange} required />
              </label>
              <label>
                Email:
                <input type="email" name="email" value={orderDetails.email} onChange={handleInputChange} required />
              </label>
              <label>
                Phone:
                <input type="tel" name="phone" value={orderDetails.phone} onChange={handleInputChange} required />
              </label>
              <label>
                Address:
                <input type="text" name="address" value={orderDetails.address} onChange={handleInputChange} required />
              </label>
              <label>
                Upload Images:
                <input type="file" multiple onChange={handleImageUpload} required />
              </label>
              {uploading && <p>Uploading images...</p>}
              {imageUrls.map((url, index) => (
                url && <img key={index} src={url} alt={`Uploaded ${index + 1}`} style={{ width: '100px', height: 'auto' }} />
              ))}
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <p>Total Price: ${calculateTotalPrice()}</p>
              <button type="submit" disabled={uploading || !orderDetails.name || !orderDetails.email || !orderDetails.phone || !orderDetails.address}>
                {uploading ? 'Uploading...' : 'Submit Order'}
              </button>
            </form>
          </div>
        )}
      </div>
      <ReviewsSection />
      <ProductContainer />
      <Footer />
    </div>
  );
};

export default OrderForm;
