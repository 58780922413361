import React, { useState, useEffect } from 'react';
import { db } from '../JavaScript/firebaseConfig';
import {
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  query,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import {
  Box,
  Typography,
  Button,
  TextField,
  Rating,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Avatar,
} from '@mui/material';
import { styled } from '@mui/system';
import '../Css/ReviewsSection_css.css';

// Styled components for a cleaner look
const ReviewContainer = styled(Box)({
  padding: '30px',
  backgroundColor: '#fafafa',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginBottom: '30px',
});

const ReviewCard = styled(Card)({
  margin: '20px 0',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
});

const LoadMoreButton = styled(Button)({
  margin: '20px auto',
  display: 'block',
  backgroundColor: '#593EA1',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#452F82',
  },
});

const ReviewSection = () => {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const [lastReviewDoc, setLastReviewDoc] = useState(null);

  const reviewsCollection = collection(db, 'reviews');

  useEffect(() => {
    fetchInitialReviews();
  }, []);

  const fetchInitialReviews = async () => {
    try {
      const queryRef = query(reviewsCollection, orderBy('timestamp', 'desc'), limit(3));
      const data = await getDocs(queryRef);
      const fetchedReviews = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setReviews(fetchedReviews);
      setLastReviewDoc(data.docs[data.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching initial reviews:', error);
    }
  };

  const fetchMoreReviews = async () => {
    try {
      if (!lastReviewDoc) return;
      const queryRef = query(
        reviewsCollection,
        orderBy('timestamp', 'desc'),
        startAfter(lastReviewDoc),
        limit(3)
      );
      const data = await getDocs(queryRef);
      const fetchedReviews = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setReviews([...reviews, ...fetchedReviews]);
      setLastReviewDoc(data.docs[data.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching more reviews:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (name.length < 2 || name.length > 20) {
      setError('Name must be between 2 and 20 characters');
      return;
    }

    if (comment.length < 10 || comment.length > 100) {
      setError('Comment must be between 10 and 100 characters');
      return;
    }

    try {
      await addDoc(reviewsCollection, {
        name,
        comment,
        rating,
        timestamp: serverTimestamp(),
      });
      setName('');
      setComment('');
      setRating(0);
      fetchInitialReviews();
    } catch (error) {
      setError('Error adding review: ' + error.message);
    }
  };

  return (
    <Box className="review-section">
      <Typography variant="h4" className="review-title" style={{ textAlign: 'center', marginBottom: '30px' }}>
        Customer Reviews
      </Typography>

      <ReviewContainer>
        <Typography variant="h6" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
          Leave Your Review
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            style={{ marginBottom: '15px' }}
          />
          <TextField
            label="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
            fullWidth
            multiline
            rows={4}
            style={{ marginBottom: '15px' }}
          />
          <Typography style={{ marginBottom: '10px' }}>Rating:</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(e, newValue) => setRating(newValue)}
            required
            style={{ marginBottom: '20px' }}
          />
          <Button type="submit" variant="contained" style={{ backgroundColor: '#593EA1', color: '#ffffff' }}>
            Submit
          </Button>
        </Box>
      </ReviewContainer>

      <Box className="reviews-list">
        {reviews.map((review) => (
          <ReviewCard key={review.id}>
            <CardHeader
              avatar={<Avatar>{review.name[0].toUpperCase()}</Avatar>}
              title={review.name}
              subheader={new Date(review.timestamp?.seconds * 1000).toLocaleDateString()}
            />
            <CardContent>
              <Rating value={review.rating} readOnly />
              <Typography variant="body2" style={{ marginTop: '10px' }}>
                {review.comment}
              </Typography>
            </CardContent>
          </ReviewCard>
        ))}

        {lastReviewDoc && (
          <LoadMoreButton onClick={fetchMoreReviews}>
            Load More Reviews
          </LoadMoreButton>
        )}
      </Box>
    </Box>
  );
};

export default ReviewSection;
