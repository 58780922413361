import React from 'react';
import '../Css/OrderNotes_css.css';

const OrderNotes = () => {
  return (
    <div className="order-notes-container">
      <div className="order-notes">
        <p>Make sure you attach high-quality square images.</p>
        <p>Delivery charge $3 inside Beirut, $5 outside will be added to the total price.</p>
        <p>You can submit your order when the pictures are uploaded successfully.</p>
      </div>
    </div>
  );
};

export default OrderNotes;
